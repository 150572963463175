import { ReactElement } from "react";
import * as yup from "yup";
import { PayoutKind, PayoutKinds } from "api/baseAPI/payouts";
import { CustomerAccountInputProps } from "pages/payout/logic/forms/components/FormLines";
import { SettlePayInput } from "pages/payout/logic/forms/components/inputs/SettlePayInput";
import { ReactComponent as SettlePayIcon } from "utils/img/settlepay.svg";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class SettlepayPayoutMethod
  extends BasePayoutMethod
  implements IPayoutMethod
{
  slug: string = "settlepay";

  kind: PayoutKind = PayoutKinds.SETTLEPAY;

  name: string = "SettlePay";

  mobileName: string = "SettlePay";

  icon: ReactElement = (<SettlePayIcon />);

  filenameExample = "sample-settlepay";

  jsonExample = [
    {
      "Счет получателя": "+380980000000",
      Сумма: 23.33,
      Примечание: "note 1",
    },
    {
      "Счет получателя": "SP000000aaaa0000",
      Сумма: 50,
      Примечание: "note 2",
    },
    {
      "Счет получателя": "+380670000000",
      Сумма: 5050,
      Примечание: "note 3",
    },
  ];

  get serviceIds() {
    return (
      (this.pointManager?.service_config?.settlepay_payouts &&
        this.getService(
          this.pointManager?.service_config?.settlepay_payouts
        )) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }

  customerAccountInput = (props: CustomerAccountInputProps) => (
    <SettlePayInput {...props} />
  );

  customerAccountValidationSchema = yup
    .string()
    .test(
      "isValid",
      "Необходимо ввести номер телефона или ID кошелька",
      (value: string | undefined): boolean => {
        if (Number(value)) {
          return !!value && value.length >= 11 && value.length <= 15;
        }
        return (
          value?.slice(0, 2) === "SP" && value.length >= 5 && value.length <= 32
        );
      }
    )
    .required("Необходимо ввести номер телефона или ID кошелька");
}
