import { FormikErrors, FormikTouched } from "formik";
import { isEmpty, reduce } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid4 } from "uuid";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { LinearProgress } from "components/LinearProgress";
import getObjectsFromFile from "utils/getObjectsFromFile";
import { useSuccessNotification } from "utils/notificationWrappers";
import { handleChangeNumber } from "utils/numbers";
import { IPayoutMethod } from "pages/payout/logic/payoutMethods";
import {
  PayoutData,
  PayoutFormData,
  randomInt,
  usePayoutForm,
  usePayoutsFromTemplate,
} from "pages/payout/logic/payoutsCreateLogic";
import { FormLines } from "./components/FormLines";
import { PayoutFormWrapper } from "./components/PayoutFormWrapper";

export const PayoutForm = ({
  payoutMethod,
}: {
  payoutMethod: IPayoutMethod;
}) => {
  const { t } = useTranslation();

  const {
    form,
    isSuccess,
    needSplitPayouts,
    setNeedSplitPayouts,
    showAddToTemplateModal,
    setShowAddToTemplateModal,
    addRow,
    deleteRow,
    handleSplitPayouts,
    isSplitting,
    additionalFields,
    isAdditionalFieldsLoading,
  } = usePayoutForm(payoutMethod);

  useSuccessNotification([{ isSuccess }]);

  const subscriber = {
    setPayouts: () => {},
    setSaveToTemplate: (isSave: boolean) => {
      form.validateForm().then((err: FormikErrors<PayoutFormData>) => {
        if (isEmpty(err)) {
          setShowAddToTemplateModal(isSave);
        } else {
          form.setTouched(err as FormikTouched<PayoutFormData>);
          form.setErrors(err);
        }
      });
    },
    setIsSubmitting: () => {},
    setDelay: (delay: number) => form.setFieldValue("delay", delay, true),
    setFormErrors: () => {},
  };

  payoutMethod?.addSubscriber("payoutForm", subscriber);

  useEffect(() => {
    payoutMethod.notifyIsSubmiting(form.isSubmitting);
  }, [form.isSubmitting]);

  useEffect(() => {
    payoutMethod.notifyFormUpdate(form.values.payouts);
  }, [form.values.payouts]);

  payoutMethod.resetForm = form.resetForm;
  payoutMethod.handleSubmit = form.handleSubmit;
  payoutMethod.handleValidate = () =>
    new Promise((resolve) => {
      form.validateForm().then((errors) => {
        if (isEmpty(errors)) {
          resolve(true);
        } else {
          form.setTouched(errors as FormikTouched<PayoutFormData>);
          form.setErrors(errors);
          resolve(false);
        }
      });
    });

  payoutMethod.handleSaveToTemplate = (save: boolean) => {
    form.setFieldValue("saveCardsToTemplate", save);
  };

  payoutMethod.errors = form.errors;
  payoutMethod.values = form.values;

  const { isLoading } = usePayoutsFromTemplate({
    payoutMethod,
    cb: (payouts) => {
      form.setFieldValue("payouts", payouts);
    },
  });

  const handleUpload = (
    files: FileList | null,
    onSuccess: (lines: Record<string, PayoutData>) => void,
    onError: (error: string) => void
  ) => {
    if (files) {
      const file = files[0];

      getObjectsFromFile(file)
        .then((object) => {
          const { commissionsData } = payoutMethod;

          if (commissionsData === null) {
            return null;
          }

          const { max_amount } = commissionsData;
          const lines: Record<string, PayoutData> = {};

          object.forEach((line) => {
            const customer_account = line[0] || "";
            let amount = String(line[1] || "")
              .replaceAll(",", ".")
              .replace(/[^0-9.]/g, "")
              .slice(0, 13);
            const description = line[2] || "";
            const fields = additionalFields?.length
              ? reduce(
                  additionalFields,
                  (acc, field, i) => ({
                    ...acc,
                    [field?.gate_key]: line[3 + i] || "",
                  }),
                  {}
                )
              : {};
            const fio = {
              first_name: additionalFields?.length ? "" : String(line[3] || ""),
              middle_name: additionalFields?.length
                ? ""
                : String(line[4] || ""),
              last_name: additionalFields?.length ? "" : String(line[5] || ""),
            };

            if (max_amount && Number(amount) <= max_amount) {
              lines[uuid4()] = {
                customer_account: String(customer_account),
                amount: handleChangeNumber(amount),
                description: String(description),
                ...fio,
                fields,
              };
            } else {
              let part_index = 1;

              while (max_amount && Number(amount) > max_amount) {
                const part_amount = randomInt(max_amount * 0.75, max_amount);

                lines[uuid4()] = {
                  customer_account: String(customer_account),
                  amount: String(part_amount),
                  description: description
                    ? `${description}; part ${part_index}`
                    : `part ${part_index}`,
                  ...fio,
                  fields,
                };

                amount = `${Number((Number(amount) - part_amount).toFixed(2))}`;
                part_index += 1;
              }

              lines[uuid4()] = {
                customer_account: String(customer_account),
                amount: String(amount),
                description: description
                  ? `${description}; part ${part_index}`
                  : `part ${part_index}`,
                ...fio,
                fields,
              };
            }
            return line;
          });

          onSuccess(lines);
        })
        .catch(() => {
          onError(t("Некорректный формат файла!"));
        });
    }
  };

  if (
    payoutMethod.isLoaded === false ||
    isLoading ||
    isAdditionalFieldsLoading
  ) {
    return (
      <Box py={20}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <PayoutFormWrapper
      payout={payoutMethod}
      payoutsData={form.values.payouts}
      resetForm={form.resetForm}
      handleSplitPayouts={handleSplitPayouts}
      isSplitting={isSplitting}
      setNeedSplitPayouts={setNeedSplitPayouts}
      needSplitPayouts={needSplitPayouts}
      setShowAddToTemplateModal={setShowAddToTemplateModal}
      showAddToTemplateModal={showAddToTemplateModal}
      addRow={addRow}
      handleUpload={(files) =>
        handleUpload(
          files,
          (lines) =>
            form.setValues({
              ...form.values,
              payouts: lines,
            }),
          (error) => {
            console.error(error);
          }
        )
      }
    >
      <>
        <Box mb={24}>
          <Grid container>
            <FormLines
              customerAccountInput={payoutMethod.customerAccountInput}
              payoutMethod={payoutMethod}
              form={form}
              deleteRow={deleteRow}
              additionalFields={additionalFields}
            />
          </Grid>
        </Box>
      </>
    </PayoutFormWrapper>
  );
};
