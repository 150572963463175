import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { FormikErrors, FormikTouched } from "formik";
import { isEmpty } from "lodash";
import { v4 as uuid4 } from "uuid";
import { LinearProgress } from "components/LinearProgress";
import { Box } from "UI/Box";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { useInfoMinAmount } from "pages/payout/logic/hooks/useInfoMinAmount";
import { IPayoutMethod } from "pages/payout/logic/payoutMethods";
import {
  PAYOUT_FIO_LIMIT,
  PayoutData,
  PayoutFormData,
  randomInt,
} from "pages/payout/logic/payoutsCreateLogic";
import {
  PayoutData as PixPayoutData,
  usePayoutsFromTemplate,
  usePixPayoutForm,
} from "pages/payout/logic/pixform";
import { FioRow } from "pages/payout/parts/FioRow";
import { ReactComponent as DeletePayoutIcon } from "pages/payout/parts/icons/delete-payout.svg";
import { getColors } from "utils/getColors";
import getObjectsFromFile from "utils/getObjectsFromFile";
import { ReactComponent as InfoIcon } from "utils/img/info.svg";
import { ReactComponent as PixInputIcon } from "utils/img/pix-input-icon.svg";
import { useSuccessNotification } from "utils/notificationWrappers";
import { handleChangeNumber, numberForInput } from "utils/numbers";
import { useCurrencyIcon } from "utils/useStyle";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { useNumberFormatter } from "hooks/useNumberFormatter";
import { useStyle } from "hooks/useStyle";
import { PayoutFormWrapper } from "./components/PayoutFormWrapper";

export const PIXPayoutForm = ({
  payoutMethod,
}: {
  payoutMethod: IPayoutMethod;
}) => {
  const { t } = useTranslation();
  const { accentColor } = getColors();
  const style = useStyle();

  const [countriesData, setCountriesData] = useState<
    {
      name: string;
      "alpha-3": string;
    }[]
  >([]);

  useEffect(() => {
    fetch("/countries.json").then((response) => {
      response
        .json()
        .then((data) => {
          setCountriesData(data);
        })
        .catch(() => {});
    });
  }, []);

  const {
    form,
    isSuccess,
    needSplitPayouts,
    setNeedSplitPayouts,
    showAddToTemplateModal,
    setShowAddToTemplateModal,
    addRow,
    deleteRow,
    handleSplitPayouts,
    isSplitting,
  } = usePixPayoutForm(payoutMethod);

  useSuccessNotification([{ isSuccess }]);

  useTranslateFormErrors(form);
  const { amountFormat } = useNumberFormatter();

  const subscriber = {
    setPayouts: () => {},
    setSaveToTemplate: (isSave: boolean) => {
      form.validateForm().then((err: FormikErrors<PayoutFormData>) => {
        if (isEmpty(err)) {
          setShowAddToTemplateModal(isSave);
        } else {
          form.setTouched(err as FormikTouched<PayoutFormData>);
          form.setErrors(err);
        }
      });
    },
    setIsSubmitting: () => {},
    setDelay: (delay: number) => form.setFieldValue("delay", delay, true),
    setFormErrors: () => {},
  };

  payoutMethod?.addSubscriber("payoutForm", subscriber);

  useEffect(() => {
    payoutMethod.notifyIsSubmiting(form.isSubmitting);
  }, [form.isSubmitting]);

  useEffect(() => {
    payoutMethod.notifyFormUpdate(form.values.payouts);
  }, [form.values.payouts]);

  payoutMethod.resetForm = form.resetForm;
  payoutMethod.handleSubmit = form.handleSubmit;
  payoutMethod.handleValidate = () =>
    new Promise((resolve) => {
      form.validateForm().then((errors: FormikErrors<PayoutFormData>) => {
        if (isEmpty(errors)) {
          resolve(true);
        } else {
          form.setTouched(errors as FormikTouched<PayoutFormData>);
          form.setErrors(errors);
          resolve(false);
        }
      });
    });
  payoutMethod.handleSaveToTemplate = (save: boolean) => {
    form.setFieldValue("saveCardsToTemplate", save);
  };

  payoutMethod.errors = form.errors;
  payoutMethod.values = form.values;

  const { isLoading } = usePayoutsFromTemplate({
    payoutMethod,
    cb: (payouts) => {
      form.setFieldValue("payouts", payouts);
    },
  });

  const handleUpload = (
    files: FileList | null,
    onSuccess: (lines: Record<string, PayoutData>) => void,
    onError: (error: string) => void
  ) => {
    if (files) {
      const file = files[0];

      getObjectsFromFile(file)
        .then((object) => {
          const { commissionsData } = payoutMethod;

          if (commissionsData === null) {
            return null;
          }

          const { max_amount } = commissionsData;
          const lines: Record<string, PayoutData> = {};

          object.forEach((line) => {
            const customer_account = line[0] || "";
            let amount = String(line[1] || "")
              .replaceAll(",", ".")
              .replace(/[^0-9.]/g, "")
              .slice(0, 13);
            const description = line[2] || "";
            const tax_id = line[3] || "";
            const tax_id_country = line[4] || "";
            const first_name = line[5] || "";
            const middle_name = line[6] || "";
            const last_name = line[7] || "";

            if (max_amount && Number(amount) <= max_amount) {
              lines[uuid4()] = {
                customer_account: String(customer_account),
                amount: handleChangeNumber(amount),
                tax_id: String(tax_id),
                tax_id_country: String(tax_id_country),
                description: String(description),
                first_name: String(first_name),
                middle_name: String(middle_name),
                last_name: String(last_name),
              };
            } else {
              let part_index = 1;

              while (max_amount && Number(amount) > max_amount) {
                const part_amount = randomInt(max_amount * 0.75, max_amount);

                lines[uuid4()] = {
                  customer_account: String(customer_account),
                  amount: String(part_amount),
                  tax_id: String(tax_id),
                  tax_id_country: String(tax_id_country),
                  description: description
                    ? `${description}; part ${part_index}`
                    : `part ${part_index}`,
                  first_name: String(first_name),
                  middle_name: String(middle_name),
                  last_name: String(last_name),
                };

                amount = `${Number((Number(amount) - part_amount).toFixed(2))}`;
                part_index += 1;
              }

              lines[uuid4()] = {
                customer_account: String(customer_account),
                amount: String(amount),
                tax_id: String(tax_id),
                tax_id_country: String(tax_id_country),
                description: description
                  ? `${description}; part ${part_index}`
                  : `part ${part_index}`,
                first_name: String(first_name),
                middle_name: String(middle_name),
                last_name: String(last_name),
              };
            }
            return line;
          });

          onSuccess(lines);
        })
        .catch(() => {
          onError(t("Некорректный формат файла!"));
        });
    }
  };

  const currencyIcon = useCurrencyIcon(payoutMethod.currency || "UAH");
  const commissions = payoutMethod.getCommissions(form.values.payouts);

  const infoMinAmount = useInfoMinAmount(
    payoutMethod.commissionsData?.min_amount || 0,
    payoutMethod.currency
  );

  if (payoutMethod.isLoaded === false || isLoading) {
    return (
      <Box py={20}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <PayoutFormWrapper
      payout={payoutMethod}
      payoutsData={form.values.payouts}
      resetForm={form.resetForm}
      handleSplitPayouts={handleSplitPayouts}
      isSplitting={isSplitting}
      setNeedSplitPayouts={setNeedSplitPayouts}
      needSplitPayouts={needSplitPayouts}
      setShowAddToTemplateModal={setShowAddToTemplateModal}
      showAddToTemplateModal={showAddToTemplateModal}
      addRow={addRow}
      handleUpload={(files) =>
        handleUpload(
          files,
          (lines: Record<string, PayoutData>) =>
            form.setValues({
              ...form.values,
              payouts: lines as unknown as Record<string, PixPayoutData>,
            }),
          (error) => {
            console.error(error);
          }
        )
      }
    >
      <>
        <Box mb={24}>
          <Grid container>
            <>
              {(
                Object.entries(form.values.payouts) as [
                  id: string,
                  payout: PayoutData
                ][]
              ).map(([id, payout], index) => (
                <Grid
                  item
                  sm={12}
                  key={id}
                  mb={15}
                  px={24}
                  pt={24}
                  style={{ background: "white" }}
                >
                  <Grid container mb={24}>
                    <Grid item sm={12}>
                      <Box
                        flex
                        justifyContent="space-between"
                        alignItems="center"
                        mb={10}
                      >
                        <Typography variant="body">
                          {`${t("Платеж")} № ${index + 1}`}
                        </Typography>
                        <Box flex alignItems="center">
                          <Typography
                            px={5}
                            py={3}
                            mr={15}
                            fontSize={14}
                            style={{
                              color: "#2C60B0",
                              backgroundColor: "#EAEFF7",
                            }}
                          >
                            {t("Комиссия")}&nbsp;
                            {currencyIcon}
                            {amountFormat(commissions[id].point)}
                          </Typography>

                          {Object.keys(form.values.payouts).length > 1 ? (
                            <DeletePayoutIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteRow(id)}
                            />
                          ) : null}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item sm={12} mb={12}>
                      <Grid
                        container
                        hSpace={10}
                        vSpace={10}
                        justifyContent="space-between"
                      >
                        <Grid item sm={12} md={4}>
                          <TextInput
                            placeholder={t("Ключ PIX")}
                            value={form.values.payouts[id].customer_account}
                            onBlur={(e) => {
                              form.handleBlur(e);
                              form.setFieldTouched(
                                `payouts[${id}].customer_account`,
                                true
                              );
                            }}
                            name="customer_account"
                            error={
                              !!(
                                form.touched.payouts?.[id]?.customer_account &&
                                form.errors.payouts?.[id]?.customer_account
                              )
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.customer_account &&
                                form.errors.payouts?.[id]?.customer_account &&
                                form.errors.payouts?.[id]?.customer_account) ||
                              ""
                            }
                            onChange={(value) => {
                              form.setFieldValue(
                                `payouts[${id}].customer_account`,
                                value.substring(0, 36)
                              );
                            }}
                            iconStart={<PixInputIcon />}
                            size="small"
                            iconEnd={
                              <>
                                <InfoIcon
                                  data-tip
                                  data-for="customer_account"
                                />
                                <ReactTooltip
                                  id="customer_account"
                                  border
                                  place="top"
                                  multiline
                                  type="dark"
                                  effect="solid"
                                  borderColor={accentColor}
                                >
                                  <span>
                                    {t(
                                      "ID пользователя, номер мобильного телефона, электронная почта или случайный ключ"
                                    )}
                                  </span>
                                </ReactTooltip>
                              </>
                            }
                          />
                        </Grid>
                        <Grid item sm={12} md={3}>
                          <TextInput
                            placeholder={t("Сумма")}
                            // mask="0000000000"
                            value={numberForInput(payout.amount)}
                            name="amount"
                            onChange={(value) =>
                              form.setFieldValue(
                                `payouts[${id}].amount`,
                                handleChangeNumber(
                                  value.replace(/[^0-9.]/g, "").slice(0, 13)
                                )
                              )
                            }
                            onBlur={(e) => {
                              form.handleBlur(e);
                              form.setFieldTouched(
                                `payouts[${id}].amount`,
                                true
                              );
                            }}
                            error={
                              !!(
                                form.touched.payouts?.[id]?.amount &&
                                form.errors.payouts?.[id]?.amount
                              )
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.amount &&
                                form.errors.payouts?.[id]?.amount) ||
                              infoMinAmount
                            }
                            size="small"
                          />
                        </Grid>
                        <Grid item sm={12} md={5}>
                          <TextInput
                            placeholder={t("Примечание")}
                            value={payout.description}
                            name="description"
                            onChange={(value) =>
                              form.setFieldValue(
                                `payouts[${id}].description`,
                                value
                              )
                            }
                            onBlur={(e) => {
                              form.handleBlur(e);
                              form.setFieldTouched(
                                `payouts[${id}].description`,
                                true
                              );
                            }}
                            error={
                              !!(
                                form.touched.payouts?.[id]?.description &&
                                form.errors.payouts?.[id]?.description
                              )
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.description &&
                                form.errors.payouts?.[id]?.description &&
                                form.errors.payouts?.[id]?.description) ||
                              ""
                            }
                            size="small"
                          />
                        </Grid>
                        <Grid item sm={12} md={7}>
                          <TextInput
                            placeholder={t("Идентификатор получателя")}
                            value={payout.tax_id}
                            name="tax_id"
                            onChange={(value) =>
                              form.setFieldValue(`payouts[${id}].tax_id`, value)
                            }
                            onBlur={(e) => {
                              form.handleBlur(e);
                              form.setFieldTouched(
                                `payouts[${id}].tax_id`,
                                true
                              );
                            }}
                            error={
                              !!(
                                form.touched.payouts?.[id]?.tax_id &&
                                form.errors.payouts?.[id]?.tax_id
                              )
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.tax_id &&
                                form.errors.payouts?.[id]?.tax_id &&
                                form.errors.payouts?.[id]?.tax_id) ||
                              ""
                            }
                            size="small"
                            iconEnd={
                              <>
                                <InfoIcon data-tip data-for="tax_id" />
                                <ReactTooltip
                                  id="tax_id"
                                  border
                                  place="top"
                                  multiline
                                  type="dark"
                                  effect="solid"
                                  borderColor={accentColor}
                                >
                                  <span>
                                    {t(
                                      "Бразильский CPF для физических лиц и CNPJ для предприятий"
                                    )}
                                  </span>
                                </ReactTooltip>
                              </>
                            }
                          />
                        </Grid>
                        <Grid item sm={12} md={5}>
                          <Select
                            placeholder={t("Страна")}
                            value={
                              payout.tax_id_country
                                ? payout.tax_id_country
                                : (() => {
                                    form.setFieldValue(
                                      `payouts[${id}].tax_id_country`,
                                      "BRA"
                                    );

                                    return "BRA";
                                  })()
                            }
                            name="amount"
                            onChange={(value) =>
                              form.setFieldValue(
                                `payouts[${id}].tax_id_country`,
                                value
                              )
                            }
                            options={
                              countriesData?.map((country) => ({
                                value: country["alpha-3"],
                                label: country.name,
                              })) || []
                            }
                            error={
                              !!(
                                form.touched.payouts?.[id]?.tax_id_country &&
                                form.errors.payouts?.[id]?.tax_id_country
                              )
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.tax_id_country &&
                                form.errors.payouts?.[id]?.tax_id_country &&
                                form.errors.payouts?.[id]?.tax_id_country) ||
                              ""
                            }
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      {payoutMethod.currency &&
                      Number(payout.amount) >
                        Number(
                          PAYOUT_FIO_LIMIT[payoutMethod.currency.toLowerCase()]
                        ) &&
                      style.is_fc ? (
                        <FioRow
                          values={{
                            first_name: form.values.payouts[id].first_name,
                            last_name: form.values.payouts[id].last_name,
                            middle_name: form.values.payouts[id].middle_name,
                          }}
                          errors={{
                            first_name: form?.errors?.payouts?.[id]?.first_name,
                            last_name: form?.errors?.payouts?.[id]?.last_name,
                            middle_name:
                              form?.errors?.payouts?.[id]?.middle_name,
                          }}
                          setFieldValue={(field, value) =>
                            form.setFieldValue(`payouts[${id}].${field}`, value)
                          }
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </>
          </Grid>
        </Box>
      </>
    </PayoutFormWrapper>
  );
};
