import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { FormikErrors, FormikTouched } from "formik";
import { isEmpty, reduce } from "lodash";
import styled from "styled-components";
import { v4 as uuid4 } from "uuid";
import { usePostRegistriesValidateMutation } from "api/baseAPI/payouts";
import { LinearProgress } from "components/LinearProgress";
import { Box } from "UI/Box";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  PayoutData as BinancePayPayoutData,
  useBinancePayPayoutForm,
  usePayoutsFromTemplate,
} from "pages/payout/logic/binancePayForm";
import { useInfoMinAmount } from "pages/payout/logic/hooks/useInfoMinAmount";
import { IPayoutMethod } from "pages/payout/logic/payoutMethods";
import {
  PAYOUT_FIO_LIMIT,
  PayoutData,
  PayoutFormData,
  randomInt,
} from "pages/payout/logic/payoutsCreateLogic";
import { FioRow } from "pages/payout/parts/FioRow";
import { Loader } from "pages/payout/parts/Loader";
import { ReactComponent as DeletePayoutIcon } from "pages/payout/parts/icons/delete-payout.svg";
import { getColors } from "utils/getColors";
import getObjectsFromFile from "utils/getObjectsFromFile";
import { ReactComponent as InfoIcon } from "utils/img/info.svg";
import { useSuccessNotification } from "utils/notificationWrappers";
import { handleChangeNumber, numberForInput } from "utils/numbers";
import { RESPONSIVE_SIZES } from "utils/tools";
import { useCurrencyIcon } from "utils/useStyle";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { useNumberFormatter } from "hooks/useNumberFormatter";
import { useStyle } from "hooks/useStyle";
import { PayoutFormWrapper } from "./components/PayoutFormWrapper";

type ValidatePayout = {
  customer_account: string;
  amount: number;
  amount_currency: string;
  description: string;
  receiver_type: string;
  u_validation_key: string;
};

export const BinancePayPayoutForm = ({
  payoutMethod,
}: {
  payoutMethod: IPayoutMethod;
}) => {
  const { t } = useTranslation();
  const { accentColor } = getColors();
  const style = useStyle();

  const [loadingValidate, setLoadingValidate] = useState(false);

  const [postRegistriesValidate] = usePostRegistriesValidateMutation();

  const {
    form,
    isSuccess,
    needSplitPayouts,
    setNeedSplitPayouts,
    showAddToTemplateModal,
    setShowAddToTemplateModal,
    addRow,
    deleteRow,
    handleSplitPayouts,
    isSplitting,
  } = useBinancePayPayoutForm(payoutMethod);

  useSuccessNotification([{ isSuccess }]);

  useTranslateFormErrors(form);
  const { amountFormat } = useNumberFormatter();

  const subscriber = {
    setPayouts: () => {},
    setSaveToTemplate: (isSave: boolean) => {
      form.validateForm().then((err: FormikErrors<PayoutFormData>) => {
        if (isEmpty(err)) {
          setShowAddToTemplateModal(isSave);
        } else {
          form.setTouched(err as FormikTouched<PayoutFormData>);
          form.setErrors(err);
        }
      });
    },
    setIsSubmitting: () => {},
    setDelay: (delay: number) => form.setFieldValue("delay", delay, true),
    setFormErrors: () => {},
  };

  payoutMethod?.addSubscriber("payoutForm", subscriber);

  useEffect(() => {
    payoutMethod.notifyIsSubmiting(form.isSubmitting);
  }, [form.isSubmitting]);

  useEffect(() => {
    payoutMethod.notifyFormUpdate(form.values.payouts);
  }, [form.values.payouts]);

  payoutMethod.resetForm = form.resetForm;
  payoutMethod.handleSubmit = form.handleSubmit;
  payoutMethod.handleValidate = () =>
    new Promise((resolve) => {
      form.validateForm().then((errors: FormikErrors<PayoutFormData>) => {
        if (isEmpty(errors)) {
          resolve(true);
        } else {
          form.setTouched(errors as FormikTouched<PayoutFormData>);
          form.setErrors(errors);
          resolve(false);
        }
      });
    });
  payoutMethod.handleSaveToTemplate = (save: boolean) => {
    form.setFieldValue("saveCardsToTemplate", save);
  };

  payoutMethod.errors = form.errors;
  payoutMethod.values = form.values;

  const { isLoading } = usePayoutsFromTemplate({
    payoutMethod,
    cb: (payouts) => {
      form.setFieldValue("payouts", payouts);
    },
  });

  const handleUpload = (
    files: FileList | null,
    onSuccess: (lines: Record<string, PayoutData>) => void,
    onError: (error: string) => void
  ) => {
    if (files) {
      const file = files[0];

      getObjectsFromFile(file)
        .then((object) => {
          const { commissionsData } = payoutMethod;

          if (commissionsData === null) {
            return null;
          }

          const { max_amount } = commissionsData;
          const lines: Record<string, PayoutData> = {};

          object.forEach((line) => {
            const customer_account = line[1] || "";
            const receiver_type = line[0] || "";
            let amount = String(line[2] || "")
              .replaceAll(",", ".")
              .replace(/[^0-9.]/g, "")
              .slice(0, 13);
            const description = line[3] || "";
            const first_name = line[4] || "";
            const middle_name = line[5] || "";
            const last_name = line[6] || "";

            if (max_amount && Number(amount) <= max_amount) {
              lines[uuid4()] = {
                customer_account: String(customer_account),
                amount: handleChangeNumber(amount),
                receiver_type: String(receiver_type),
                description: String(description),
                first_name: String(first_name),
                middle_name: String(middle_name),
                last_name: String(last_name),
              };
            } else {
              let part_index = 1;

              while (max_amount && Number(amount) > max_amount) {
                const part_amount = randomInt(max_amount * 0.75, max_amount);

                lines[uuid4()] = {
                  customer_account: String(customer_account),
                  amount: String(part_amount),
                  receiver_type: String(receiver_type),
                  description: description
                    ? `${description}; part ${part_index}`
                    : `part ${part_index}`,
                  first_name: String(first_name),
                  middle_name: String(middle_name),
                  last_name: String(last_name),
                };

                amount = `${Number((Number(amount) - part_amount).toFixed(2))}`;
                part_index += 1;
              }

              lines[uuid4()] = {
                customer_account: String(customer_account),
                amount: String(amount),
                receiver_type: String(receiver_type),
                description: description
                  ? `${description}; part ${part_index}`
                  : `part ${part_index}`,
                first_name: String(first_name),
                middle_name: String(middle_name),
                last_name: String(last_name),
              };
            }
            return line;
          });
          const linesNotEmailForValidate = reduce(
            lines,
            (acc: any, value, key) => {
              if (value?.receiver_type === "BINANCE_ID") {
                acc.push({
                  customer_account: value.customer_account,
                  amount: value.amount,
                  amount_currency: payoutMethod.currency,
                  description: value.description,
                  u_validation_key: key,
                  fields: {
                    receiver_type: value.receiver_type,
                  },
                });
              }
              return acc;
            },
            [] as ValidatePayout[]
          );
          if (
            linesNotEmailForValidate?.length &&
            payoutMethod &&
            payoutMethod.serviceId &&
            payoutMethod.pointId &&
            payoutMethod.wallet
          ) {
            setLoadingValidate(true);
            postRegistriesValidate({
              account_wallet_id: payoutMethod.wallet.id,
              point_id: payoutMethod.pointId,
              kind: payoutMethod.kind,
              service_id: payoutMethod.serviceId,
              payouts: linesNotEmailForValidate,
            })
              .unwrap()
              .then((data) => {
                (
                  Object.entries(data) as [
                    id: string,
                    value: { is_failed: boolean }
                  ][]
                ).forEach(([id, value]) => {
                  if (value.is_failed) {
                    form.setFieldValue(
                      `payouts[${id}].is_failed_validation`,
                      true
                    );
                    form.setFieldError(
                      `payouts[${id}].customer_account`,
                      t("Получатель отсутствует в системе Binance Pay")
                    );
                  }
                });
                onSuccess(lines);
              })
              .finally(() => {
                setLoadingValidate(false);
              });
            return null;
          }
          onSuccess(lines);
          return null;
        })
        .catch(() => {
          onError(t("Некорректный формат файла!"));
        });
    }
  };

  const handleValidateCustomerAccount = (id: string) => {
    if (
      form.values.payouts[id].receiver_type !== "EMAIL" &&
      form.values.payouts[id].customer_account &&
      form.values.payouts[id].amount &&
      payoutMethod &&
      payoutMethod.serviceId &&
      payoutMethod.pointId &&
      payoutMethod.wallet
    ) {
      postRegistriesValidate({
        account_wallet_id: payoutMethod.wallet.id,
        point_id: payoutMethod.pointId,
        kind: payoutMethod.kind,
        service_id: payoutMethod.serviceId,
        payouts: [
          {
            customer_account: form.values.payouts[id].customer_account,
            amount: form.values.payouts[id].amount,
            amount_currency: payoutMethod.currency || "",
            u_validation_key: id,
            fields: {
              receiver_type: form.values.payouts[id].receiver_type,
            },
            ...(form.values.payouts[id].description
              ? {
                  description: form.values.payouts[id].description,
                }
              : {}),
          },
        ],
      })
        .unwrap()
        .then((data) => {
          (
            Object.entries(data) as [
              id: string,
              value: { is_failed: boolean }
            ][]
          ).forEach(([itemId, value]) => {
            if (value.is_failed) {
              form.setFieldError(
                `payouts[${itemId}].customer_account`,
                t("Получатель отсутствует в системе Binance Pay")
              );
              form.setFieldValue(
                `payouts[${itemId}].is_failed_validation`,
                true
              );
            }
          });
        })
        .finally(() => {
          setLoadingValidate(false);
        });
    }
  };

  const currencyIcon = useCurrencyIcon(payoutMethod.currency || "UAH");
  const commissions = payoutMethod.getCommissions(form.values.payouts);

  const infoMinAmount = useInfoMinAmount(
    payoutMethod.commissionsData?.min_amount || 0,
    payoutMethod.currency
  );

  if (payoutMethod.isLoaded === false || isLoading) {
    return (
      <Box py={20}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <PayoutFormWrapper
      payout={payoutMethod}
      payoutsData={form.values.payouts}
      resetForm={form.resetForm}
      handleSplitPayouts={handleSplitPayouts}
      isSplitting={isSplitting}
      setNeedSplitPayouts={setNeedSplitPayouts}
      needSplitPayouts={needSplitPayouts}
      setShowAddToTemplateModal={setShowAddToTemplateModal}
      showAddToTemplateModal={showAddToTemplateModal}
      addRow={addRow}
      handleUpload={(files) =>
        handleUpload(
          files,
          (lines: Record<string, PayoutData>) =>
            form.setValues({
              ...form.values,
              payouts: lines as unknown as Record<string, BinancePayPayoutData>,
            }),
          (error) => {
            console.error(error);
          }
        )
      }
    >
      <>
        {loadingValidate && <Loader />}

        <Box mb={24}>
          <Grid container>
            <>
              {(
                Object.entries(form.values.payouts) as [
                  id: string,
                  payout: PayoutData
                ][]
              ).map(([id, payout], index) => (
                <Grid
                  item
                  sm={12}
                  key={id}
                  mb={15}
                  px={24}
                  pt={24}
                  style={{ background: "white" }}
                >
                  <Grid container mb={24}>
                    <Grid item sm={12}>
                      <Box
                        flex
                        justifyContent="space-between"
                        alignItems="center"
                        mb={10}
                      >
                        <Typography variant="body">
                          {`${t("Платеж")} № ${index + 1}`}
                        </Typography>
                        <Box flex alignItems="center">
                          <Typography
                            px={5}
                            py={3}
                            mr={15}
                            fontSize={14}
                            style={{
                              color: "#2C60B0",
                              backgroundColor: "#EAEFF7",
                            }}
                          >
                            {t("Комиссия")}&nbsp;
                            {currencyIcon}
                            {amountFormat(commissions[id].point)}
                          </Typography>

                          {Object.keys(form.values.payouts).length > 1 ? (
                            <DeletePayoutIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteRow(id)}
                            />
                          ) : null}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item sm={12} mb={12}>
                      <Grid
                        container
                        hSpace={10}
                        vSpace={10}
                        justifyContent="space-between"
                      >
                        <Grid item sm={12} md={3}>
                          <Select
                            placeholder={t("Тип получателя")}
                            value={form.values.payouts[id].receiver_type}
                            name="receiver_type"
                            onChange={(value) => {
                              form.setFieldValue(
                                `payouts[${id}].receiver_type`,
                                value
                              );
                              form.setFieldValue(
                                `payouts[${id}].customer_account`,
                                ""
                              );
                              form.setFieldValue(
                                `payouts[${id}].is_failed_validation`,
                                false
                              );
                              form.setFieldError(
                                `payouts[${id}].customer_account`,
                                ""
                              );
                            }}
                            options={[
                              {
                                value: "Binance ID",
                                label: "Binance ID",
                              },
                              {
                                value: "Email",
                                label: "Email",
                              },
                            ]}
                            error={
                              !!(
                                form.touched.payouts?.[id]?.receiver_type &&
                                form.errors.payouts?.[id]?.receiver_type
                              )
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.receiver_type &&
                                form.errors.payouts?.[id]?.receiver_type &&
                                form.errors.payouts?.[id]?.receiver_type) ||
                              ""
                            }
                            size="small"
                          />
                        </Grid>
                        <Grid item sm={12} md={5}>
                          <TextInput
                            placeholder={
                              form.values.payouts[id].receiver_type === "EMAIL"
                                ? "Email"
                                : "Binance ID"
                            }
                            value={form.values.payouts[id].customer_account}
                            onBlur={(e) => {
                              form.handleBlur(e);
                              form.setFieldTouched(
                                `payouts[${id}].customer_account`,
                                true
                              );
                              handleValidateCustomerAccount(id);
                            }}
                            name="customer_account"
                            error={
                              !!(
                                form.touched.payouts?.[id]?.customer_account &&
                                form.errors.payouts?.[id]?.customer_account
                              )
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.customer_account &&
                                form.errors.payouts?.[id]?.customer_account &&
                                form.errors.payouts?.[id]?.customer_account) ||
                              ""
                            }
                            onChange={(value) => {
                              form.setFieldError(
                                `payouts[${id}].customer_account`,
                                ""
                              );
                              form.setFieldValue(
                                `payouts[${id}].customer_account`,
                                form.values.payouts[id].receiver_type ===
                                  "EMAIL"
                                  ? value
                                  : value.replace(/[^0-9]/g, "").slice(0, 10)
                              );
                              form.setFieldValue(
                                `payouts[${id}].is_failed_validation`,
                                false
                              );
                            }}
                            size="small"
                            iconEnd={
                              form.values.payouts[id].receiver_type ===
                              "EMAIL" ? (
                                <StyledTooltipWrapper>
                                  <InfoIcon
                                    data-tip
                                    data-for="customer_account"
                                  />
                                  <ReactTooltip
                                    id="customer_account"
                                    className="tooltipCustomerAccount"
                                    border
                                    place="top"
                                    multiline
                                    type="dark"
                                    effect="solid"
                                    borderColor={accentColor}
                                  >
                                    <span>
                                      {t(
                                        "Отправляя выплату, используя параметр email, вы соглашетесь с риском возможной блокировки средств на 72 часа в случае, если у получателя отсутствует аккаунт Binance."
                                      )}
                                    </span>
                                  </ReactTooltip>
                                </StyledTooltipWrapper>
                              ) : (
                                <></>
                              )
                            }
                          />
                        </Grid>
                        <Grid item sm={12} md={4}>
                          <TextInput
                            placeholder={t("Введите сумму")}
                            // mask="0000000000"
                            value={numberForInput(payout.amount)}
                            name="amount"
                            onChange={(value) =>
                              form.setFieldValue(
                                `payouts[${id}].amount`,
                                handleChangeNumber(
                                  value.replace(/[^0-9.]/g, "").slice(0, 13)
                                )
                              )
                            }
                            onBlur={(e) => {
                              form.handleBlur(e);
                              form.setFieldTouched(
                                `payouts[${id}].amount`,
                                true
                              );
                              handleValidateCustomerAccount(id);
                            }}
                            error={
                              !!(
                                form.touched.payouts?.[id]?.amount &&
                                form.errors.payouts?.[id]?.amount
                              )
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.amount &&
                                form.errors.payouts?.[id]?.amount) ||
                              infoMinAmount
                            }
                            size="small"
                          />
                        </Grid>
                        <Grid item sm={12}>
                          <TextInput
                            placeholder={t("Введите комментарий")}
                            value={payout.description}
                            name="description"
                            onChange={(value) =>
                              form.setFieldValue(
                                `payouts[${id}].description`,
                                value
                              )
                            }
                            onBlur={(e) => {
                              form.handleBlur(e);
                              form.setFieldTouched(
                                `payouts[${id}].description`,
                                true
                              );
                            }}
                            error={
                              !!(
                                form.touched.payouts?.[id]?.description &&
                                form.errors.payouts?.[id]?.description
                              )
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.description &&
                                form.errors.payouts?.[id]?.description &&
                                form.errors.payouts?.[id]?.description) ||
                              ""
                            }
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      {payoutMethod.currency &&
                      Number(payout.amount) >
                        Number(
                          PAYOUT_FIO_LIMIT[payoutMethod.currency.toLowerCase()]
                        ) &&
                      style.is_fc ? (
                        <FioRow
                          values={{
                            first_name: form.values.payouts[id].first_name,
                            last_name: form.values.payouts[id].last_name,
                            middle_name: form.values.payouts[id].middle_name,
                          }}
                          errors={{
                            first_name: form?.errors?.payouts?.[id]?.first_name,
                            last_name: form?.errors?.payouts?.[id]?.last_name,
                            middle_name:
                              form?.errors?.payouts?.[id]?.middle_name,
                          }}
                          setFieldValue={(field, value) =>
                            form.setFieldValue(`payouts[${id}].${field}`, value)
                          }
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </>
          </Grid>
        </Box>
      </>
    </PayoutFormWrapper>
  );
};

const StyledTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  .tooltipCustomerAccount {
    width: 300px;
  }
  @media (${RESPONSIVE_SIZES.md}) {
    .tooltipCustomerAccount {
      width: 150px;
    }
  }
`;
