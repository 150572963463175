import { useTranslation } from "react-i18next";
import { TextInput } from "UI/Form/TextInput";
import { CustomerAccountInputProps } from "pages/payout/logic/forms/components/FormLines";

export const SettlePayInput = (props: CustomerAccountInputProps) => {
  const { t } = useTranslation();
  const { id, form } = props;
  return (
    <TextInput
      onChange={(value) => {
        form.setFieldError(`payouts[${id}].customer_account`, "");
        if (value) {
          if (value.slice(0, 2) === "SP") {
            form.setFieldValue(
              `payouts[${id}].customer_account`,
              value.slice(0, 32)
            );
          } else if (Number(value) || value === "0" || value?.[0] === "+") {
            const phoneValue = value === "0" ? "+" : value;
            form.setFieldValue(
              `payouts[${id}].customer_account`,
              phoneValue
                ? `+${phoneValue.replace(/[^0-9]/g, "").slice(0, 14)}`
                : ""
            );
          } else {
            form.setFieldValue(
              `payouts[${id}].customer_account`,
              value.slice(0, 32)
            );
            if (
              value.length > 1 &&
              (value.slice(0, 2) !== "SP" || !Number(value))
            ) {
              form.setFieldTouched(`payouts[${id}].customer_account`, true);
              form.setFieldError(
                `payouts[${id}].customer_account`,
                "Необходимо ввести номер телефона или ID кошелька"
              );
            }
          }
        } else {
          form.setFieldValue(`payouts[${id}].customer_account`, "");
        }
      }}
      placeholder={t("Счет получателя")}
      name="customer_account"
      value={form.values.payouts[id].customer_account}
      onBlur={(e) => {
        form.handleBlur(e);
        form.setFieldTouched(`payouts[${id}].customer_account`, true);
      }}
      error={
        !!(
          form.touched.payouts?.[id]?.customer_account &&
          form.errors.payouts?.[id]?.customer_account
        )
      }
      helperText={
        t<any>(
          form.touched.payouts?.[id]?.customer_account &&
            form.errors.payouts?.[id]?.customer_account &&
            form.errors.payouts?.[id]?.customer_account
        ) || ""
      }
      size="small"
    />
  );
};
